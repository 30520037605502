body {
	-webkit-text-size-adjust: 100%;

	-ms-overflow-x: hidden;
}

.clear {
	clear: both;
}

#site {
	position: relative;
	min-height: 100%;
}

main {
	width: 100%;
}

.shoppingcart-popup {
	display: none;
}

/* apply a natural box layout model to all elements */
*,
*:before,
*:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
